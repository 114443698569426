import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  margins, mobileThresholdPixels, fontSizes, Title2, Subtitle, P, Button,
}
  from '../home/v2/StyledComponents';
import dessin1 from '../../assets/partners/dessin1.png';
import dessin2 from '../../assets/partners/dessin2.png';

const Title1 = styled(Title2.withComponent('h1'))`
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

const Img = styled.img`
  max-width: 30vw;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column-reverse;
  }
`;

const Column = styled.div`
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  margin: ${margins.m} ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px ${margins.s} ${margins.l};
  }
`;

const P1Container = styled.div`
  text-align: center;
`;

const Ampersand = styled.p`
  font-size: 100px;
  margin: -40px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 60px;
    margin: 0px;
  }
`;

const PartnerBlock1 = ({ openModal }) => (
  <Container>
    <Title1 big>
      Divisez par 2 votre taux de retour...
      <br />
      En bichonnant vos clients
    </Title1>
    <Subtitle>
      Une alternative innovante aux retours colis
    </Subtitle>
    <Row>
      <Column>
        <Img src={dessin1} alt="" />
        <P1Container>
          <P>
            Réduction de votre taux de retour colis
          </P>
        </P1Container>
      </Column>
      <Ampersand>&</Ampersand>
      <Column>
        <Img src={dessin2} alt="" />
        <P1Container>
          <P>
            Augmentation de votre
            <br />
            satisfaction client grâce
            <br />
            à un service sur-mesure
          </P>
        </P1Container>
      </Column>
    </Row>
    <Row>
      <Button onClick={openModal}>
        Recevoir le pack
        <br />
        Tilli
      </Button>
    </Row>
  </Container>
);

PartnerBlock1.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default PartnerBlock1;
