import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isMobile from '../../services/isMobile';
import {
  margins, mobileThresholdPixels, Title2, Subtitle, Button,
} from '../home/v2/StyledComponents';
import Schema3 from '../../assets/partners/schema3.png';
import Schema3Mobile from '../../assets/partners/schema3mobile.png';

const Container = styled.div`
  margin-bottom: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: ${margins.l};
  }
`;

const Img = styled.img`
  width: 80vw;
  margin: ${margins.xl};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PartnerBlock3 = ({ openModal }) => (
  <Container>
    <Title2 big>
      intégrer Tilli en 1 clic
    </Title2>
    <Subtitle>
      votre site e-commerce
    </Subtitle>

    <Column>
      <Img src={isMobile() ? Schema3Mobile : Schema3} alt="" />
    </Column>

    <Row>
      <Button onClick={openModal}>
        Recevoir le pack
        <br />
        Tilli
      </Button>
    </Row>
  </Container>
);

PartnerBlock3.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default PartnerBlock3;
