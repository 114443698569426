import React from 'react';

import { Container, Content } from '../../components/home/homeStyledComponents';
import MenuBar from '../../components/home/MenuBar';
import YourPartner from '../../components/home/YourPartner';
import PluginModal from '../../components/partner/PluginModal';
import Footer from '../../components/home/Footer';
import callApi from '../../services/api';
import Layout from '../../layouts/index';

class PartnersPage extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openModal() {
    if (typeof window !== 'undefined') window.scrollTo(0, 0);
    this.setState({ showModal: true });
  }

  submit() {
    const { brandName, brandColor } = this.state;
    const { alterationsType, alterationsOffer, alterationsOfferAmount } = this.state;
    const { contactName, contactEmail, contactPhone } = this.state;
    const partnerDemand = {
      brandName,
      brandColor,
      alterationsType,
      alterationsOffer,
      alterationsOfferAmount,
      contactName,
      contactEmail,
      contactPhone,
    };
    return callApi('partnerDemands', 'post', { partnerDemand }).then(() => this.closeModal());
  }

  renderModal() {
    return (
      <PluginModal
        onChange={(key, value) => this.setState({ [key]: value })}
        alterationsType={this.state.alterationsType}
        alterationsOffer={this.state.alterationsOffer}
        alterationsOfferAmount={this.state.alterationsOfferAmount}
        closeModal={this.closeModal}
        submit={this.submit}
      />
    );
  }

  render() {
    if (this.state.showModal) return this.renderModal();
    return (
      <Layout routeSlug="WhyBecomePartner">
        <Container style={{ overflow: this.state.showModal ? 'hidden' : 'visible' }}>
          <Content>
            <MenuBar />
            <YourPartner openModal={this.openModal} />
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

export default PartnersPage;
