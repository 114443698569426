import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label } from './home/v2/StyledComponents';
import { colors, margins, mobileThresholdPixels } from './styledComponents';

const Container = styled.div`
  margin: ${margins.l} 0px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Radio = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: ${(props) => props.checked ? colors.navy : colors.white};
  border-radius: 50%;
  border-color: ${colors.navy};
  border-width: 1px;
  border-style: solid;
  margin-right: ${margins.l};

  :hover {
    background-color: ${(props) => props.checked ? colors.navy : colors.sand};
  }
`;

const Space = styled.span`
  padding-left: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    padding-left: ${margins.s};
  }
`;

const InputRadio = ({
  children, value, checked, onClick,
}) => (
  <Container onClick={onClick}>
    <Radio checked={checked} />
    <Space>
      <Label small htmlFor={value}>{children}</Label>
    </Space>
  </Container>
);

InputRadio.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.node,
  checked: PropTypes.boolean,
  onClick: PropTypes.func,
};

InputRadio.defaultProps = {
  children: '',
  checked: false,
  onClick() {},
};

export default InputRadio;
