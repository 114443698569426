import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import withAppContext from '../../withAppContext';
import routesMap from '../../Routes';
import { trackEvent } from '../../services/analytics';
import {
  mobileThresholdPixels, fontSizes, colors, margins, Title2, Subtitle, Button, Content,
}
  from '../home/v2/StyledComponents';
import tilliXBourrienne from '../../assets/partners/tilliXBourrienne.jpg';
import tilliXApc from '../../assets/partners/tilliXApc.jpg';
import tilliXSezane from '../../assets/partners/tilliXSezane.jpg';
import tilliXMarant from '../../assets/partners/tilliXMarant.jpg';

const Container = styled.div`
  margin-bottom: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: ${margins.m};
  }
`;

const StyledContent = styled(Content)`
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0px 0px ${margins.s} 0px;
  ${(props) => props.withMarginTop && `margin-top: ${margins.xl}`};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 0px ${margins.m};
  height: 250px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: unset;
  }
`;

const TextContainer = styled.div`
  font-size: ${fontSizes.l};
  color: ${colors.navy};
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    margin: ${margins.s} 0px ${margins.s};
    width: 100%;
  }
`;

const Img = styled.img`
  ${(props) => props.top && `
    width: 49%;
    height: 280px;
  `}
  ${(props) => props.middle && `
    width: 60%;
    height: 350px;
  `}
  ${(props) => props.bottom && `
    width: 100%;
    height: 380px;
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    height: ${(props) => props.heigh};
    margin: ${margins.s} 0px;
  }
`;

class PartnerBlock4 extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { isMobile } = this.props.context;
    return (
      <Container>
        <StyledContent>
          <Title2 big>Créez un écosystème responsable</Title2>
          <Subtitle>en allant plus loin dans l’intégration Tilli</Subtitle>
          {!isMobile
            ? (
              <div>
                <Row withMarginTop>
                  <Img src={tilliXBourrienne} alt="Tilli x Bourrienne" top />
                  <Img src={tilliXApc} alt="Tilli x APC" top />
                </Row>
                <Row>
                  <Img src={tilliXSezane} alt="Tilli x Sézane" middle />
                  <Column>
                    <TextContainer>
                      Créez une expérience personnalisée
                      {' '}
                      <br />
                      {' '}
                      pour vos clients en ajoutant
                      {' '}
                      <br />
                      des briques Tilli à l’ensemble
                      {' '}
                      <br />
                      {' '}
                      de votre parcours
                    </TextContainer>
                    <Link
                      to={routesMap.EcosystemPage.url}
                      onClick={() => trackEvent('click', 'en-savoir-plus_devenirPartenaire')}
                    >
                      <Button>En savoir plus</Button>
                    </Link>
                  </Column>
                </Row>
                <Row><Img src={tilliXMarant} alt="Tilli x Isabel Marant" bottom /></Row>
              </div>
            )
            : (
              <Column>
                <Img src={tilliXMarant} alt="Tilli x Isabel Marant" />
                <TextContainer>
                  Créez une expérience personnalisée pour vos clients en ajoutant
                  des briques Tilli à l’ensemble de votre parcours
                </TextContainer>
                <Link
                  to={routesMap.EcosystemPage.url}
                  onClick={() => trackEvent('click', 'en-savoir-plus_devenirPartenaire')}
                >
                  <Button>En savoir plus</Button>
                </Link>
                <Img src={tilliXSezane} alt="Tilli x Sézane" />
              </Column>
            )}
        </StyledContent>
      </Container>
    );
  }
}

PartnerBlock4.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(PartnerBlock4);
