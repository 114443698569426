import React from 'react';
import styled from 'styled-components';

import isMobile from '../../services/isMobile';
import {
  Title2, Subtitle, colors, margins, fontSizes, mobileThresholdPixels, smallDesktopThresholdPixels,
}
  from '../home/v2/StyledComponents';
import schema1 from '../../assets/partners/schema2_1.png';
import schema2 from '../../assets/partners/schema2_2.png';
import schema3 from '../../assets/partners/schema2_3.png';
import schema4 from '../../assets/partners/schema2_4.png';
import schema5 from '../../assets/partners/schema2_5.png';
import schema6 from '../../assets/partners/schema2_6.png';
import schema7 from '../../assets/partners/schema2_7.png';

import logoTilli from '../../assets/partners/LogoTilli.png';

const firstRow = [
  {
    index: 1,
    title: 'site',
    text: <span>
      Navigation et
      <br />
      choix d‘une pièce
    </span>,
    img: schema1,
  },
  {
    index: 2,
    title: 'page produit',
    text: <span>
      Page produit avec infos
      <br />
      sur le service de
      <br />
      retouche
    </span>,
    img: schema2,
    displayTilli: true,
  },
  {
    index: 3,
    title: 'colis',
    text: <span>Achat - réception - doute</span>,
    img: schema3,
    displayTilli: true,
    extraMarginNegative: true,
  },
];

const secondRow = [
  {
    index: 4,
    title: 'mail',
    text: <span>
      Réception mail post colis
      <br />
      avec lien pour
      <br />
      commande de retouche
    </span>,
    img: schema4,
    displayTilli: true,
  },
  {
    index: 5,
    title: 'page des commandes',
    text: <span>
      Plugin Tilli sur votre site
      <br />
      comme alternative aux
      <br />
      retours colis
    </span>,
    img: schema5,
    displayTilli: true,
  },
  {
    index: 6,
    title: 'retouche',
    text: <span>RDV Tilliste</span>,
    img: schema6,
    displayTilli: true,
  },
];

const Container = styled.div`
  margin-bottom: ${margins.xl};
`;

const Row = styled.div`
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
`;

const Block = styled.div`
  display: inline-block;
  margin: ${margins.l} ${margins.xl};
  max-width: 100vw;

  @media (max-width: ${smallDesktopThresholdPixels}) {
    margin: ${margins.l};
  }
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.m} 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    ${(props) => props.rowOnMobile && props.isEven ? 'flex-direction: row-reverse;' : null}
    ${(props) => props.rowOnMobile && !props.isEven ? 'flex-direction: row;' : null}
    ${(props) => props.rowOnMobile ? 'justify-content: space-around;' : null}
    ${(props) => props.rowOnMobile ? 'width: 100vw;' : null}
  }
`;

const BlockTitle = styled.h4`
  color: ${colors.navy};
  font-size: 26px;
  text-transform: uppercase;
  text-align: center;
  margin: ${margins.m} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 20px;
    margin: 0px;
  }
`;

const BlockTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 130px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: inherit;
  }
`;

const BlockText = styled.p`
  color: ${colors.navy};
  font-size: ${fontSizes.l};
  font-family: Roboto;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const BlockImg = styled.img`
  max-height: 280px;
  ${(props) => props.extraMarginNegative ? 'margin-right: -110px;' : null}

  @media (max-width: ${mobileThresholdPixels}) {
    max-height: 35vw;
    ${(props) => props.extraMarginNegative ? 'margin-right: -50px;' : null}
  }
`;

const BlockImgLarge = styled.img`
  max-height: 400px;
  margin-right: -108px;
  margin-top: -40px;

  @media (max-width: ${mobileThresholdPixels}) {
    max-height: 60vw;
    margin-top: -20px;
    margin-right: -60px;
  }
`;

const BlockLogo = styled.img`
  max-width: 200px;
  margin-top: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    max-width: 25vw;
  }
`;

const BlockTilliLogo = <BlockLogo src={logoTilli} />;

const renderBlock = (block, index) => (
  <Block key={block.index}>
    <Column rowOnMobile isEven={(index % 2) === 0}>
      <Column style={{ flex: '1 1' }}>
        <BlockTitle>{block.title}</BlockTitle>
        <BlockTextContainer>
          <BlockText>{block.text}</BlockText>
        </BlockTextContainer>
      </Column>
      <Column style={{ flex: '1 1' }}>
        <BlockImg src={block.img} alt="" extraMarginNegative={block.extraMarginNegative} />
        {block.displayTilli ? BlockTilliLogo : null}
      </Column>
    </Column>
  </Block>
);

const PartnerBlock2 = () => (
  <Container>
    <Title2 big>
      Plug Tilli en 1 ligne de code !
    </Title2>
    <Subtitle>
      Quelle intégration dans votre site e-commerce ?
    </Subtitle>
    <Column>
      <Row>{firstRow.map(renderBlock)}</Row>
      <Row>{secondRow.map((block, index) => renderBlock(block, index + 1))}</Row>
      <Row style={isMobile() ? { marginTop: '50px' } : { marginTop: '100px' }}>
        <BlockTitle>Vente !</BlockTitle>
        <BlockText>Pas de retour colis</BlockText>
        <BlockImgLarge src={schema7} alt="" />
      </Row>
    </Column>
  </Container>
);

export default PartnerBlock2;
